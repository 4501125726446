<template>
  <div class="my-44">
    <div class="relative">
      <img class="w-8 absolute bottom-0 right-0 -mb-5 mr-2" src="/images/filledComma90.png" alt="">
      <h1 class="border border-orange text-orange py-3 text-center uppercase font-bold text-xl">We help through</h1>
      <img class="w-8 absolute top-0 left-0 -mt-5 ml-2" src="/images/filledComma.png" alt="">
    </div>

    <div class="xl:px-24 px-4 mt-24">
      <div
          class="grid xl:grid-cols-3 grid-cols-1 xl:gap-24 gap-14">

        <div class="xl:px-0 lg:px-64 md:px-40 sm:px-32 px-0">
          <div class="relative">
            <div class="flex justify-center">
              <img class="rectangle-shape grayscale object-cover" src="/images/supporting.JPG" alt="">
            </div>
            <h1 class="bg-dark text-center skew-x-6 px-10 py-4 text-orange bg-black text-2xl uppercase bottom-0 xl:-ml-10 -ml-0 ml-4 mb-10 absolute font-bold">
              Supporting</h1>
          </div>
          <p class="text-dark text-md text-start my-3">Makers originally started as a start-up, and our journey is
            something we want others to benefit from, therefore supporting start-ups scaling up their business with the
            following:

            Social media presence,
            Informative website,
            Desk space,
            Product making,
            Consultancy and many more.
          </p>
        </div>

        <div class="xl:px-0 lg:px-64 md:px-40 sm:px-32 px-0">
          <div class="relative">
            <div class="flex justify-center">
              <img class="rectangle-shape grayscale object-cover " src="/images/training.JPG" alt="">
            </div>
            <h1 class="bg-dark skew-x-6 px-10 py-4 text-orange bg-black text-2xl uppercase bottom-0 xl:-ml-10 -ml-0 ml-4 mb-10 absolute font-bold">
              Training</h1>
          </div>
          <p class="text-dark text-md text-start my-3">We provide a wide variety of technical courses and internships
            that made our vision of expanding the community of Makers with the skills of 3D modeling, product design and
            manufacturing, cinema 4D, electronics, robotics, AI, and programming.</p>
        </div>

        <div class="xl:px-0 lg:px-64 md:px-40 sm:px-32 px-0">
          <div class="relative">
            <div class="flex justify-center">
              <img class="rectangle-shape grayscale object-cover " src="/images/making.JPG" alt="">
            </div>
            <h1 class="bg-dark skew-x-6 px-10 py-4 text-orange bg-black text-2xl uppercase bottom-0 xl:-ml-10 -ml-0 ml-4 mb-10 absolute font-bold">
              Making</h1>
          </div>
          <p class="text-dark text-md text-start my-3">We've equipped the maker space to design and make prototypes and
            products.
            We've made 90+ final usable products with the experience and dedication of our team, interns, start-ups, and
            students.
          </p>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServicesComponent"
}
</script>

<style scoped>

</style>