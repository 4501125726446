<template>
  <div class="my-44">
    <div class="relative">
      <img class="w-8 absolute bottom-0 right-0 -mb-5 mr-2" src="/images/filledComma90.png" alt="">
      <h1 class="border border-orange text-orange py-3 text-center uppercase font-bold text-xl">What you can do in our
        space
      </h1>
      <img class="w-8 absolute top-0 left-0 -mt-5 ml-2" src="/images/filledComma.png" alt="">
    </div>


    <div class="lg:px-24 px-4 mt-24">
      <div class="flex lg:flex-row flex-col justify-between items-center my-36 lg:space-y-0 space-y-14">
        <div class="lg:w-5/12 w-full">
          <h1 class="text-dark font-bold text-3xl">Meet and Work</h1>
          <h1 class="text-orange font-semibold">at the open space</h1>
          <p class="mt-6">
            The open space promotes communication and collaboration among peers, leading to better teamwork and
            problem-solving. It's tailored to be supportive and a productive environment, contributing to the success of
            individuals.
          </p>
        </div>
        <div class="lg:w-6/12 w-full grid bg-pattern bg-contain bg-repeat py-10">
          <img alt="" src="/images/work.JPG"
               class="lg:w-8/12 w-9/12 lg:h-44 h-28 rounded-full object-cover object-center grayscale z-10">
          <img alt="" src="/images/meet.JPG"
               class="lg:w-8/12 w-9/12 lg:h-44 h-28 rounded-full lg:-mt-10 -mt-4 justify-self-end object-cover object-center grayscale">
        </div>
      </div>
      <div class="flex lg:flex-row-reverse flex-col justify-between items-center my-36 lg:space-y-0 space-y-14 ">
        <div class="lg:w-5/12 w-full">
          <h1 class="text-dark font-bold text-3xl">Build and Make</h1>
          <h1 class="text-orange font-semibold">at the maker space</h1>
          <p class="mt-6">
            The Maker space provides access to a variety of tools and equipment that
            you may not have at home, such as 3D printers, laser cutters, and woodworking tools.
            as well as opportunities to collaborate with like-minded individuals, share ideas and knowledge, and
            participate in workshops and events.

          </p>
        </div>
        <div class="lg:w-6/12 w-full grid bg-pattern bg-contain bg-repeat py-10">
          <img alt="" src="/images/build.JPG"
               class="lg:w-8/12 w-9/12 lg:h-44 h-28 rounded-full object-cover object-center grayscale">
          <img alt="" src="/images/make.JPG"
               class="lg:w-8/12 w-9/12 lg:h-44 h-28 rounded-full lg:-mt-10 -mt-4 justify-self-end object-cover object-center grayscale">
        </div>
      </div>
      <div class="flex lg:flex-row flex-col justify-between items-center my-36 lg:space-y-0 space-y-14">
        <div class="lg:w-5/12 w-full">
          <h1 class="text-dark font-bold text-3xl">Scale up and Network</h1>
          <h1 class="text-orange font-semibold">at the co-work area</h1>
          <p class="mt-6">
            Co-work area offers start-ups the resources and support needed to grow their businesses, while providing a
            cost-effective, flexible, and productive working environment.
            and mainly providing opportunities for start-ups to network with other entrepreneurs, potential partners,
            and investors, helping to grow their businesses.
          </p>
        </div>
        <div class="lg:w-6/12 w-full grid bg-pattern bg-contain bg-repeat py-10">
          <img alt="" src="/images/supporting.JPG"
               class="lg:w-8/12 w-9/12 lg:h-44 h-28 rounded-full object-cover object-center grayscale z-10">
          <img alt="" src="/images/network.JPG"
               class="lg:w-8/12 w-9/12 lg:h-44 h-28 rounded-full lg:-mt-10 -mt-4 justify-self-end object-cover object-center grayscale">
        </div>
      </div>
      <div class="flex lg:flex-row-reverse flex-col justify-between items-center my-36 lg:space-y-0 space-y-14 ">
        <div class="lg:w-5/12 w-full">
          <h1 class="text-dark font-bold text-3xl">Learn and Participate</h1>
          <h1 class="text-orange font-semibold">at the training halls</h1>
          <p class="mt-6">
            Holding a variety of technical skills training that provides the tools and knowledge needed to improve job
            performance, advance careers, and enhance professional development.
            The training halls are available for booking and to hold training programs, providing resources and the
            environment needed to run effective and professional training programs, while being cost-effective and
            flexible.
          </p>
        </div>
        <div class="lg:w-6/12 w-full grid bg-pattern bg-contain bg-repeat py-10">
          <img alt="" src="/images/learn.JPG"
               class="lg:w-8/12 w-9/12 lg:h-44 h-28 rounded-full object-cover object-center grayscale">
          <img alt="" src="/images/participate.JPG"
               class="lg:w-8/12 w-9/12 lg:h-44 h-28 rounded-full lg:-mt-10 -mt-4 justify-self-end object-cover object-center grayscale">
        </div>
      </div>
    </div>

  </div>


</template>

<script>
export default {
  name: "SpaceComponent"
}
</script>

<style scoped>

</style>