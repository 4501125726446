<template>

  <div class="my-44">

    <div class="relative">
      <img class="absolute z-10 lg:w-96 w-52 top-0 right-0" src="/images/bg-pattern.svg" alt="">
      <img class="rounded-full bg-dark w-full grayscale lg:h-72 h-44 object-cover object-no-repeat"
           src="" alt="">
      <h1 class="text-orange font-bold text-3xl mt-10">Makers Team</h1>
      <p class="mt-6 lg:w-8/12 w-full">Makers team embody company's mission and vision, working together to support its members
        and create a vibrant and thriving community. with proven passion about
        innovation, and creativity.
        Makers are also dedicated to continuous learning and improvement, staying up-to-date with the latest trends
        and developments in innovation and creativity.</p>
    </div>


  </div>
</template>

<script>
export default {
  name: "TeamComponent"
}
</script>

<style scoped>

</style>