<template>
  <div class="bg-dark py-6 px-4">
    <h1 class="text-sm text-white">© 2023 Makers.</h1>
  </div>
</template>

<script>
export default {
  name: "FooterComponent"
}
</script>

<style scoped>

</style>