<template>
  <div class="my-44">
    <div class="relative">
      <img class="w-8 absolute bottom-0 right-0 -mb-5 mr-2" src="/images/filledComma90.png" alt="">
      <h1 class="border border-orange text-orange py-3 text-center uppercase font-bold text-xl">Our location
      </h1>
      <img class="w-8 absolute top-0 left-0 -mt-5 ml-2" src="/images/filledComma.png" alt="">
    </div>
    <div class="my-44 flex xl:flex-row flex-col-reverse relative text-white">
      <a class="w-full xl:w-8/12 xl:-mt-0 -mt-10 z-10" href="https://goo.gl/maps/PW7hJ5znqVYK68WL6">
        <img class="w-full" src="/images/location.svg" alt="">
      </a>
      <img class="w-full hidden xl:flex" src="/images/locationDark.svg" alt="">
      <div
          class="xl:absolute xl:bg-transparent bg-dark top-0 xl:w-5/12 w-full xl:right-0 xl:mr-24 mr-0 xl:pt-0 xl:pb-0 pt-14 pb-24 xl:mt-10 mt-6 xl:text-center text-start xl:px-0 px-6">
        <h1 class="xl:text-2xl text-2xl font-bold align-center 2xl:pt-6 pt-0">You can find us at Al Sinaa St near Alkhasaky
          Sweets</h1>
        <p class="text-sm mt-4 font-bold">Visit the space any time from 9:00 AM to 9:00 PM</p>
        <a href="https://goo.gl/maps/PW7hJ5znqVYK68WL6" class="text-sm mt-4 font-bold text-orange mt-2">Our location on
          Google Maps</a>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: "LocationComponent"
}
</script>

<style scoped>

</style>