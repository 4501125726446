import { render, staticRenderFns } from "./WelcomeComponent.vue?vue&type=template&id=b5c06da2&"
import script from "./WelcomeComponent.vue?vue&type=script&lang=js&"
export * from "./WelcomeComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./WelcomeComponent.vue?vue&type=custom&index=0&blockType=link&rel=stylesheet&href=%2Fsrc%2Finput.css"
if (typeof block0 === 'function') block0(component)

export default component.exports